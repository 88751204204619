<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-around">
            <div class="card-body mb-3 pb-0 col-12">
                <router-link :to="{name: 'PeopleGroupCreateCoursesAdmin', params: {idCourse: this.paramsId}}" class="btn btn-current float-right"><i class="fas fa-plus mr-2"></i>Add new</router-link>
                <h2 class="fw-400 font-lg d-block"><b>Group</b></h2>
            </div>
            <div class="col-10">
                <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
            </div>
            <div class="col-2">
                <select class="form-control" v-model="cycle">
                    <option value="1">Term 1</option>
                    <option value="2">Term 2</option>
                    <option value="3">Term 3</option>
                </select>
            </div>
            <div class="card-body w-100">
                <div class="row">
                    <div v-if="!isLoad" class="col-12 text-center py-3">
                        <div class="table-responsive mw-100 pr-2 ml-0">
                            <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="data" :page="1" :perPage="10">
                                <template name="default" slot-scope="{ row, index }">
                                    <tr v-if="row">
                                        <td>{{ index+1 }}</td>
                                        <td>Term {{ row.cycle }}</td>
                                        <td>{{ row.name }}</td>
                                        <td>
                                            <router-link :to="{name: 'PeopleGroupMemberCoursesAdmin', params: {idCourse: paramsId, idGroup: row.id}}" class="btn btn-sm btn-primary">{{row.member ? row.member.length : 0}}</router-link>
                                        </td>
                                        <td>
                                            <router-link :to="{name: 'PeopleGroupEditCoursesAdmin', params: {idCourse: paramsId, idGroup: row.id}}" class="btn btn-sm btn-warning text-white mr-2"><i class="fas fa-edit"></i></router-link>
                                            <a href="javascript:void(0)" @click="deleteAlert(row.id)" class="btn btn-sm btn-danger"><i class="fas fa-trash"></i></a>
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td colspan="8">Nothing to see here</td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>
                        <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                    </div>
                    <div v-else class="col-12 text-center py-3">
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Everyone',
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            isLoad: true,
            cycle: 1,
            data: [],
            page: 1,
            tableFilter: '',
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Cycle', field: 'cycle', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Name', field: 'name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Member', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Action', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},                
            ]
        }
    },
    created(){
        this.getGroup()
    },
    methods:{
        async getGroup(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/people/group?slug=${this.paramsId}&cycle=${this.cycle}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.data = res.data.data
                this.isLoad = false
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        _method: 'delete',
                        slug: this.paramsId,
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/people/group`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Group!',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getGroup()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Group!',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    },
    watch: {
        cycle(){
            this.getGroup()
        }
    }
}
</script>